import React, { useCallback, useEffect, useState } from "react";
import { graphql } from "gatsby";
import { Row, Col, Space } from "antd";
import {
  ContainerCta,
  InternalLinkTypes,
  Layout,
  SEO,
  ContainerContent,
  BannerPage,
  ContainerContentFixed,
  ContentDisclaimer,
  DisplayFacadesList,
  Colors,
  TitleSection,
  DisplayFloorPlanList,
  DisplayHomeDesignList,
  RANGES_COLORS,
  StoreyFilter,
  Filter,
} from "../components";

export default ({ data }) => {
  const range = data.allRangesJson.nodes[0];
  const facades = data.allFacadesJson.nodes;
  const floorplans = data.allFloorPlansJson.nodes;
  const homeDesigns = data.allHomeDesignsJson.nodes;
  const [filteredPlans, setFilteredPlans] = useState([]);
  const [filteredFacades, setFilteredFacades] = useState([]);
  const [filteredHomes, setFilteredHomes] = useState([]);

  const [selectedBed, setSelectedBed] = useState("all");
  const onChangeSelectedBed = useCallback(
    value => {
      setSelectedBed(value);
    },
    [setSelectedBed]
  );

  const [selectedBath, setSelectedBath] = useState("all");
  const onChangeSelectedBath = useCallback(
    values => {
      setSelectedBath(values);
    },
    [setSelectedBath]
  );

  const [selectedLiving, setSelectedLiving] = useState("all");
  const onChangeSelectedLiving = useCallback(
    values => {
      setSelectedLiving(values);
    },
    [setSelectedLiving]
  );

  const [selectedGarage, setSelectedGarage] = useState("all");
  const onChangeSelectedGarage = useCallback(
    values => {
      setSelectedGarage(values);
    },
    [setSelectedGarage]
  );

  const [selectedLotWidth, setSelectedLotWidth] = useState("all");
  const onChangeSelectedLotWidth = useCallback(
    value => {
      setSelectedLotWidth(value);
    },
    [setSelectedLotWidth]
  );
  const [selectedLotLength, setSelectedLotLength] = useState("all");
  const onChangeSelectedLotLength = useCallback(
    value => {
      setSelectedLotLength(value);
    },
    [setSelectedLotLength]
  );

  const [filterOptions] = useState(() => {
    let options = {
      beds: [{ value: "all", text: "All" }],
      baths: [{ value: "all", text: "All" }],
      living: [{ value: "all", text: "All" }],
      garage: [{ value: "all", text: "All" }],
      lotWidth: [{ value: "all", text: "All" }],
      lotLength: [{ value: "all", text: "All" }],
    };
    let beds = new Set();
    let baths = new Set();
    let living = new Set();
    let garage = new Set();
    let lotWidth = new Set();
    let lotLength = new Set();
    floorplans.forEach(fp => {
      beds.add(fp.info.bed);
      baths.add(fp.info.bath);
      living.add(fp.info.living);
      garage.add(fp.info.carSpace);
      lotWidth.add(fp.info.lotWidth);
      lotLength.add(fp.info.lotLength);
    });
    Array.from(beds)
      .sort((a, b) => parseFloat(a) - parseFloat(b))
      .forEach(item => options.beds.push({ value: item, text: `${item}` }));

    Array.from(baths)
      .sort((a, b) => parseFloat(a) - parseFloat(b))
      .forEach(item => options.baths.push({ value: item, text: `${item}` }));

    Array.from(living)
      .sort((a, b) => parseFloat(a) - parseFloat(b))
      .forEach(item => options.living.push({ value: item, text: `${item}` }));

    Array.from(garage)
      .sort((a, b) => parseFloat(a) - parseFloat(b))
      .forEach(item => options.garage.push({ value: item, text: `${item}` }));

    Array.from(lotWidth)
      .sort((a, b) => parseFloat(a) - parseFloat(b))
      .forEach(item =>
        options.lotWidth.push({ value: item, text: `${item}m` })
      );

    Array.from(lotLength)
      .sort((a, b) => parseFloat(a) - parseFloat(b))
      .forEach(item =>
        options.lotLength.push({ value: item, text: `${item}m` })
      );

    return options;
  });
  const [selectedStorey, setSelectedStorey] = useState("all");
  const onChangeSelectedStorey = useCallback(
    value => {
      setSelectedStorey(value);
    },
    [setSelectedStorey]
  );

  useEffect(() => {
    let filtered = [];
    facades.forEach(plan => {
      if (
        selectedStorey.toString() === plan.storey.toString() ||
        selectedStorey.toString() === "all"
      )
        filtered.push(plan);
    });

    setFilteredFacades(filtered);
  }, [facades, selectedStorey]);
  useEffect(() => {
    let filtered = [];
    homeDesigns.forEach(home => {
      if (
        selectedStorey.toString() === home.info.storey.toString() ||
        selectedStorey.toString() === "all"
      )
        filtered.push(home);
    });

    setFilteredHomes(filtered);
  }, [homeDesigns, selectedStorey]);

  useEffect(() => {
    let filtered = [];
    floorplans.forEach(plan => {
      if (
        (selectedStorey.toString() === plan.info.storey.toString() ||
          selectedStorey.toString() === "all") &&
        (selectedBed.toString() === plan.info.bed.toString() ||
          selectedBed.toString() === "all") &&
        (selectedBath.toString() === plan.info.bath.toString() ||
          selectedBath.toString() === "all") &&
        (selectedLiving.toString() === plan.info.living.toString() ||
          selectedLiving.toString() === "all") &&
        (selectedGarage.toString() === plan.info.carSpace.toString() ||
          selectedGarage.toString() === "all") &&
        (selectedLotWidth.toString() === plan.info.lotWidth.toString() ||
          selectedLotWidth.toString() === "all") &&
        (selectedLotLength.toString() === plan.info.lotLength.toString() ||
          selectedLotLength.toString() === "all")
      )
        filtered.push(plan);
    });

    setFilteredPlans(filtered);
  }, [
    floorplans,
    selectedStorey,
    selectedBed,
    selectedBath,
    selectedLiving,
    selectedGarage,
    selectedLotWidth,
    selectedLotLength,
  ]);
  return (
    <Layout>
      <SEO title={range.name} />
      <BannerPage
        title={range.name}
        subTitle={range.description}
        titleStyles={{ color: RANGES_COLORS[range.range] }}
      />
      {/*<ContainerContent style={{ padding: 0 }}>*/}
      {/*  <Img*/}
      {/*    fluid={range.imagePath.childImageSharp.fluid}*/}
      {/*    style={{ maxHeight: 500 }}*/}
      {/*  />*/}
      {/*</ContainerContent>*/}
      <ContainerContent style={{ backgroundColor: Colors.LightGray }}>
        <ContainerContentFixed>
          <TitleSection>Facades</TitleSection>
          <Space direction="vertical" size={16}>
            <Row gutter={[16, 16]}>
              <Col>
                <StoreyFilter
                  selectedFilter={selectedStorey}
                  onChangeFilter={onChangeSelectedStorey}
                />
              </Col>
            </Row>{" "}
            <DisplayFacadesList facades={filteredFacades} />
          </Space>
          <br />
          <br />
          <TitleSection>Floor Plans</TitleSection>
          <Space direction="vertical" size={16}>
            <Row gutter={[16, 16]}>
              <Col>
                <StoreyFilter
                  selectedFilter={selectedStorey}
                  onChangeFilter={onChangeSelectedStorey}
                />
              </Col>
              <Col>
                <Filter
                  label={"Bedroom"}
                  selectedFilter={selectedBed}
                  onChangeFilter={onChangeSelectedBed}
                  filterOptions={filterOptions.beds}
                />
              </Col>
              <Col>
                <Filter
                  label={"Bathroom"}
                  selectedFilter={selectedBath}
                  onChangeFilter={onChangeSelectedBath}
                  filterOptions={filterOptions.baths}
                />
              </Col>
              <Col>
                <Filter
                  label={"Living area"}
                  selectedFilter={selectedLiving}
                  onChangeFilter={onChangeSelectedLiving}
                  filterOptions={filterOptions.living}
                />
              </Col>
              <Col>
                <Filter
                  label={"Car Space"}
                  selectedFilter={selectedGarage}
                  onChangeFilter={onChangeSelectedGarage}
                  filterOptions={filterOptions.garage}
                />
              </Col>
              <Col>
                <Filter
                  label={"Fits Lot Frontage"}
                  selectedFilter={selectedLotWidth}
                  onChangeFilter={onChangeSelectedLotWidth}
                  filterOptions={filterOptions.lotWidth}
                />
              </Col>
              <Col>
                <Filter
                  label={"Fits Lot Depth"}
                  selectedFilter={selectedLotLength}
                  onChangeFilter={onChangeSelectedLotLength}
                  filterOptions={filterOptions.lotLength}
                />
              </Col>
            </Row>
            <DisplayFloorPlanList floorplans={filteredPlans} />
          </Space>
          <br />
          <br />
          <TitleSection>HOME DESIGNS</TitleSection>
          <Space direction="vertical" size={16}>
            <Row gutter={[16, 16]}>
              <Col>
                <StoreyFilter
                  selectedFilter={selectedStorey}
                  onChangeFilter={onChangeSelectedStorey}
                />
              </Col>
            </Row>{" "}
            <DisplayHomeDesignList homeDesigns={filteredHomes} />
          </Space>
        </ContainerContentFixed>
      </ContainerContent>
      <ContainerContent>
        <ContainerContentFixed style={{ textAlign: "center" }}>
          <section style={{ textAlign: "center" }}>
            <ContentDisclaimer />
          </section>
        </ContainerContentFixed>
      </ContainerContent>
      <ContainerCta
        title={"Can't find the home design you like?"}
        subTitle={"Don't worry, we can build one as per your needs"}
        secondaryText={"Customise your own"}
        secondaryLink={InternalLinkTypes.GetStarted}
      />
    </Layout>
  );
};

export const query = graphql`
  query($range: String!) {
    allRangesJson(filter: { range: { eq: $range } }) {
      nodes {
        name
        range
        description
        imagePath {
          childImageSharp {
            fluid(maxWidth: 1800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    allFacadesJson(filter: { range: { eq: $range } }) {
      nodes {
        slug
        name
        storey
        range
        imagePath {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    allFloorPlansJson {
      nodes {
        name
        slug
        displayLink
        info {
          size
          storey
          bed
          bath
          carSpace
          living
          lotWidth
          lotLength
        }
        imagePath {
          id
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    allHomeDesignsJson(filter: { range: { eq: $range } }) {
      nodes {
        name
        description
        youtubeLink
        slug
        range
        info {
          storey
        }
        albumImagePath {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
